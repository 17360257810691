import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';
import { EnvService } from './core/services/env.service';


export function createApollo(
  httpLink: HttpLink,
  envService: EnvService,
): ApolloClientOptions<any> {
  return {
    link: httpLink.create({
      uri: envService.getConfig()['appSyncUrl'],
      headers: new HttpHeaders({
        'x-api-key': envService.getConfig()['appSyncUriKey'],
      }),
    }),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, EnvService],
    },
  ],
})
export class GraphQLModule {}
