import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql.module';
import { AuthModule, AuthClientConfig, AuthConfig } from '@auth0/auth0-angular';
import { EnvService } from './core/services/env.service';
import { initializeApp } from './core/services/app-initializer.service';

export function configFactory(envService: EnvService, authClientConfig: AuthClientConfig): () => Promise<void> {
  return () => {
    const config = envService.getConfig();
    authClientConfig.set({
      domain: config['auth0domain'],
      clientId: config['auth0clientId'],
      authorizationParams: {
        redirect_uri: `${window.location.origin}`,
      },
    } as AuthConfig);
    return Promise.resolve();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    GraphQLModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [EnvService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [EnvService, AuthClientConfig],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
