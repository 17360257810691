export enum RouteConstants {
  VOD_CONTENT = 'content_management/vod_content',
  CHANNEL = 'content_management/channel',
  EPG = 'content_management/epg',
  DASHBOARD = 'dashboard',
  CONTENT_MANAGEMENT = 'content_management',
  ADVERTISEMENTS = 'advertisement',
  ERROR = 'error',
  COMING_SOON = 'error/comingSoon',
  WORKFLOWS = 'workflows_&_tasks',
  PLAN_CONFIGURATOR = 'content_management/package_configurator',
  HUMAN_TASKS = 'content_management/human_tasks',
  WELCOME = 'error/welcome',
}
