import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteConstants} from './shared/utils/route-constants';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./core-features/core-features.module').then(
        (m) => m.CoreFeaturesModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: RouteConstants.ERROR,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
