import { EnvService } from './env.service';
// import { IrdetoAuthService } from './irdeto-auth.service';

export function irdetoAuthServiceFactory(envService: EnvService) {
//   const config = envService.getConfig();
//   return new IrdetoAuthService(envService);
}

export function initializeApp(envService: EnvService) {
  return () => envService.loadConfig().then(() => {
  }).catch(err => {
    console.error('Error during app initialization:', err);
  });
}
